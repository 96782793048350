<template>
  <div
    @click.stop.prevent="$emit('close')"
    :class="{
      'fixed bg-overlay h-screen': !notpopup,
      'relative mt-4': notpopup,
    }"
    class="flex items-center justify-center top-0 left-0 w-full z-50"
  >
    <div
      @click="close"
      v-html="closeIcon"
      class="absolute right-6 top-6 cursor-pointer"
    ></div>
    <div
      @click.stop.prevent
      :id="notpopup ? '' : 'call-window'"
      :class="{ 'h-full p-16': !notpopup, 'ml-4 mr-4 p-8': notpopup }"
      class="bg-white relative rounded-3xl w-full mx-auto shadow-lg flex items-center justify-center"
    >
      <div class="flex items-center justify-center text-center">
        <div>
          <div
            id="failed-icon"
            v-html="cancelIcon"
            class="mx-auto flex items-center justify-center relative text-center"
          ></div>
          <div class="w-full text-header" style="line-height:1.6em">
            Apmokėjimas nepavyko
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { db } from "./videoMessenger/fire.js";
import anime from "animejs/lib/anime.es.js";
import { mapState } from "vuex";

export default {
  props: {
    notpopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      cancelIcon:
        '<svg xmlns="http://www.w3.org/2000/svg" stroke="#F9D77A" width="122" height="122" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>',
      closeIcon:
        '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
    };
  },
  mounted() {
    this.drawSuccess();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      appliedDiscount: (state) => state.appliedDiscount,
      cartContent: (state) => state.cartContent,
    }),
  },
  methods: {
    drawSuccess() {
      anime({
        targets: "#failed-icon path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 1500,
        delay: function(el, i) {
          return i * 250;
        },
        loop: false,
      });
    },
    close() {
      this.$router.push("/");
    },
  },
  watch: {
    user() {},
  },
};
</script>

<style lang="scss">
.arrow-up {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}
#scroll-cart {
  overflow: hidden;
}
#no-popup {
  #scroll-cart {
    max-height: 100%;
    overflow: hidden;
  }
}
</style>
